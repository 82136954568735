      <!-- Main Content -->
    <main>
      <!-- Hero Section -->
      <section class="hero">
        <div class="container">
          <div class="hero_area">

            <!-- Hero area content -->
            <div class="hero_content">
              <div class="left_aside_menu">
                <!-- aside header menu -->
                <div class="as_ct_search">
                  <input
                    type="search"
                    id="searchTour"
                    placeholder="搜索國家/地區/地方/關鍵詞/團號"
                    value=""
                    (keyup.enter)="searchTour()"
                  />
                  <button type="button" (keyup.enter)="searchTour()" (click)="searchTour()">
                    <i class="fas fa-search">搜尋</i>
                  </button>
                </div>  
                <div
                class="aside_header_menu"
                style="display: block; position: inherit" >
                <ul  >
                  <li class="as_menu_item" id="as_menu" *ngFor="let data of categoryList " >
                    <span *ngIf="storeParentId(data)" > {{ data.parentName }}</span>
                    <div class="as_sub_menu" id="as_sub_menu">
                      <h3 class="as_sub_menu_title" ><a href="tour?loc={{data.categoryId}}&type=top&title=全部{{data.parentName}}">全部{{ data.parentName}}</a></h3>
                      <ul >
                        <li *ngFor="let dataSub of filterListSub  ">
                          <a href="/tour?loc={{ dataSub.categoryId}}&type=sub" [innerHTML]="dataSub.categoryName">
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  
                </ul>
                
                
              </div>
              <iframe *ngFor="let tsdata of sidetripshoot" width="100%" height="200px" [src]="tsdata" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <div *ngIf="fbslides.length">
                <c-carousel  [animate]="true" transition="slide" [interval]="5000" [touch]="true" >
                  <c-carousel-indicators></c-carousel-indicators>
                  <c-carousel-inner>
                    <c-carousel-item *ngFor="let slideData of fbslides">
                      <a href="{{slideData.title}}"  target="_blank">
                      <img
                        [src]="slideData.src"
                        alt="{{slideData.subtitle}}"
                        class="d-block w-100"
                        loading="lazy" />
                        </a>
              
                    </c-carousel-item>
                  </c-carousel-inner>
                </c-carousel>
              </div>
            
              </div>
                <div class="hero_banner">
                  <div class="hero_banner_slide" style="display:flex" *ngIf="bannerslides.length">
                    <a target="_blank" *ngFor="let banner of bannerslides" [href]="banner.src"><img src={{banner.src}} style="width:100%"></a>
                  </div>
                  <div class="hero_banner_slide" *ngIf="slides.length">
                    <c-carousel  [animate]="true" transition="crossfade" [interval]="3000" >
                      <c-carousel-indicators></c-carousel-indicators>
                      <c-carousel-inner>
                        <c-carousel-item *ngFor="let slide of slides">
                          <a href="{{slide.title}}" >
                          <img
                            [src]="slide.src"
                            alt="{{slide.subtitle}}"
                            class="d-block w-100"
                            loading="lazy" />
                            </a>
                  
                        </c-carousel-item>
                      </c-carousel-inner>
                    </c-carousel>
                  </div>
                </div>
              <!--
              <div class="right_aside_menu">
                <ul>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=85292229610&text=你好，想詢問有關資料。&type=phone_number&app_absent=0" target="_blank">
                      <img
                        src="/assets/img/aside-menu/whatsapp.svg"
                        alt="icon"
                      />
                      <span>
                        WhatsApp 查詢 / 報名
                        <br />
                        9222 9610</span
                      >
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send/?phone=85291375396&text=你好，想詢問有關包團資料。&type=phone_number&app_absent=0" target="_blank">
                      <img
                        src="/assets/img/aside-menu/whatsapp.svg"
                        alt="icon"
                      />
                      <span>
                        WhatsApp 包團
                        <br />
                        9137 5396</span
                      >
                    </a>
                  </li>
     
                  <li>
                    <a href="tour?loc=9999">
                      <img src="/assets/img/aside-menu/tourIcn.svg" alt="icon" />
                      旅行團
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="/assets/img/aside-menu/cruisesIcn.svg"
                        alt="icon"
                      />
                      熱賣推介
                    </a>
                  </li>
                  <li>
                    <a href="tour?loc=173">
                      <img
                        src="/assets/img/aside-menu/20201103-hk_icon.svg"
                        alt="icon"
                      />
                      香港本地遊
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/assets/img/aside-menu/LH.svg" alt="lh" />
                      各國旅遊須知
                    </a>
                  </li>
                  <li>
                    <a href="https://www.hko.gov.hk/tc/wxinfo/worldwx/wwi.htm" target="_blank">
                      <img src="/assets/img/aside-menu/icon-5.png" alt="lh" />
                      天氣預測
                    </a>
                  </li>
                  <li>
                    <a href="/news">
                      <img src="/assets/img/aside-menu/icon-6.svg" alt="lh" />
                      公司消息
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/assets/img/aside-menu/icon-7.svg" alt="lh" />
                      出遊隨拍
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/assets/img/aside-menu/icon-8.svg" alt="lh" />
                      廣告精選
                    </a>
                  </li>
                </ul>
              </div>
            -->
<!-- new bar -->
<div class="right_aside_menu">
  <ul>
    <li>
      <a href="https://api.whatsapp.com/send/?phone=85292229610&text=你好，想詢問有關資料。&type=phone_number&app_absent=0" target="_blank">
        <div>
          <iconify-icon icon="akar-icons:whatsapp-fill"></iconify-icon>
          <span>
            WhatsApp 查詢/報名
            <br />
            9222 9610</span>
        </div>
      </a>
    </li>
    <li>
      <a href="https://api.whatsapp.com/send/?phone=85291375396&text=你好，想詢問有關包團資料。&type=phone_number&app_absent=0" target="_blank">
        <div>
          <iconify-icon icon="akar-icons:whatsapp-fill"></iconify-icon>
          <span>
            WhatsApp 包團
            <br />
            9137 5396</span>
        </div>
      </a>
    </li>
    <li>
      <a href="tour?loc=9999">
        <div>
          <iconify-icon icon="lets-icons:group-light"></iconify-icon>
          旅行團
        </div>
      </a>
    </li>
    <li>
      <a href="tour?loc=9999&title=熱賣精選">
        <div>
          <iconify-icon
            icon="ic:outline-settings-suggest"
          ></iconify-icon>
          熱賣精選
        </div>
      </a>
    </li>
    <li>
      <a href="tour?loc=172&type=top">
        <div>
          <iconify-icon icon="icon-park-outline:local"></iconify-icon>
          香港本地遊
        </div>
      </a>
    </li>
    <!-- Ok -->
    <li>
      <a href="/info">
        <div>
          <iconify-icon
            icon="material-symbols:travel-explore"
          ></iconify-icon>
          各國旅遊須知
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.hko.gov.hk/tc/wxinfo/worldwx/wwi.htm" target="_blank">
        <div>
          <iconify-icon
            icon="fluent:weather-hail-day-24-regular"
          ></iconify-icon>
          天氣預測
        </div>
      </a>
    </li>
    <li>
      <a href="/news">
        <div>
          <iconify-icon icon="game-icons:newspaper"></iconify-icon>
          公司消息
        </div>
      </a>
    </li>
    <li>
      <a href="/tripshoot">
        <div>
          <iconify-icon icon="typcn:video-outline"></iconify-icon>
          出遊隨拍
        </div>
      </a>
    </li>
    
    <li>
      <a href="/lecture">
        <div>
          <iconify-icon icon="icons8:advertising"></iconify-icon>
          旅遊講座
        </div>
      </a>
    </li>
    <li>
      <a href="/contactus">
        <div>
          <iconify-icon icon="icon-park-outline:branch-one"></iconify-icon>
          分行資料 
        </div>
      </a>
    </li>

 
  </ul>

</div>
            </div>

          </div>

        </div>
    

      </section>
      <!-- end New bar-->
      <!-- Hero area -->
      <!-- Features -->
     
      <!-- Features -->
      <!-- Blogs -->
      <section class="blogs">
        <div class="container">
          <div class="blogs_wrapper">
            <!-- Blog Header -->
            <div class="blog_header" style="margin-top:40px">
              <h2>精選行程 </h2>
            </div>
            <div class="blogs_content">
              <!-- Single blog -->
              <div class="single_blog"  *ngFor="let tour of TourList">
                <div class="blog_card_thumb">
                  <img *ngIf="tour.imageName" src="/assets/img/tour/{{ tour.tourCode }}/{{ tour.imageName}}" alt="thumb" (click)="loadItineraryPage(tour.tourCode)" />
                  <img *ngIf="!tour.imageName" src="/assets/img/tour/blank_img01.jpg" alt="thumb" (click)="loadItineraryPage(tour.tourCode)" />

                </div>
                <div class="blog_card_text">
                  <div class="blog_card_text_top">
                    <h3 [innerHTML]="tour.tourName"></h3>
                    <div>
                      <h4 class="tour_code">{{ tour.tourCode}}</h4>
                      <p >
                        <button type="submit" (click)="dlPDF(tour.brochureLink)" style="cursor: pointer;color:blue"> 下載行程</button>

                      </p>
                    </div>
                    <div class="startDate" style="border:solid;border-width:thin;padding:5px; border-radius: 1ch;height:120px" >
                      <span style="font-weight: 400">出發日期：</span>
                      <div style="display:block; column-count:3">
                        <div *ngFor="let departDate of tour.departureDateFrom.split(','); let cnt = index" >
                          <span *ngIf="cnt<10">{{ departDate | date:"dd/MM/YY" }}</span>
                          <span *ngIf="cnt == 10"  (click)="loadItineraryPage(tour.tourCode)" style="cursor:pointer;"><u>更多</u></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="blog_card_text_bottom">
                    <ul>
                      <li class="priceStyle">HK {{tour.tourPrice|currency: 'HKD': 'symbol-narrow': '0.0'}}+/位</li>
                    </ul>
                    <button
                    type="button"
                    class="selectTour"
                    data-show-cl-in="rand_cl-3"
                    (click)="loadItineraryPage(tour.tourCode)"
                    >
                    選擇出發日期
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Blogs -->
    </main>
