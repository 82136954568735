<!-- new bar -->
<div class="right_aside_menu">
    <ul>
      <li>
        <a href="https://api.whatsapp.com/send/?phone=85292229610&text=你好，想詢問有關資料。&type=phone_number&app_absent=0" target="_blank">
          <div>
            <iconify-icon icon="akar-icons:whatsapp-fill"></iconify-icon>
            <span>
              WhatsApp 查詢/報名
              <br />
              9222 9610</span>
          </div>
        </a>
      </li>
      <li>
        <a href="https://api.whatsapp.com/send/?phone=85291375396&text=你好，想詢問有關包團資料。&type=phone_number&app_absent=0" target="_blank">
          <div>
            <iconify-icon icon="akar-icons:whatsapp-fill"></iconify-icon>
            <span>
              WhatsApp 包團
              <br />
              9137 5396</span>
          </div>
        </a>
      </li>
      <li>
        <a href="tour?loc=9999">
          <div>
            <iconify-icon icon="lets-icons:group-light"></iconify-icon>
            旅行團
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div>
            <iconify-icon
              icon="ic:outline-settings-suggest"
            ></iconify-icon>
            熱賣推介
          </div>
        </a>
      </li>
      <li>
        <a href="tour?loc=172&type=top">
          <div>
            <iconify-icon icon="icon-park-outline:local"></iconify-icon>
            香港本地遊
          </div>
        </a>
      </li>
      <!-- Ok -->
      <li>
        <a href="/info">
          <div>
            <iconify-icon
              icon="material-symbols:travel-explore"
            ></iconify-icon>
            各國旅遊須知
          </div>
        </a>
      </li>
      <li>
        <a href="https://www.hko.gov.hk/tc/wxinfo/worldwx/wwi.htm" target="_blank">
          <div>
            <iconify-icon
              icon="fluent:weather-hail-day-24-regular"
            ></iconify-icon>
            天氣預測
          </div>
        </a>
      </li>
      <li>
        <a href="/news">
          <div>
            <iconify-icon icon="game-icons:newspaper"></iconify-icon>
            公司消息
          </div>
        </a>
      </li>
      <li>
        <a href="/tripshoot">
          <div>
            <iconify-icon icon="typcn:video-outline"></iconify-icon>
            出遊隨拍
          </div>
        </a>
      </li>
      
      <li>
        <a href="/lecture">
          <div>
            <iconify-icon icon="icons8:advertising"></iconify-icon>
            旅遊講座
          </div>
        </a>
      </li>
   
    </ul>
  </div>