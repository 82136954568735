    <!-- Footer content -->
    <footer style="margin-top:30px">
        <div class="container">
          <div class="footer_content">
            <div class="footer_top">
              <!-- Single Widget -->
              <div class="footer_widget">
                <h3 class="widget_title">旅遊產品</h3>
                <ul class="widget-content">
                  <li><a href="tour?loc=9999&title=旅行團">旅行團</a></li>
                  <li><a href="tour?loc=100&type=sub&title=自由行<br>套票">自遊行套票</a></li>
                  <li><a href="tour?loc=100&type=sub&title=郵輪">郵輪</a></li>
                  <li><a href="https://api.whatsapp.com/send/?phone=85291375396&text=你好，想詢問有關包團資料。&type=phone_number&app_absent=0" target="_blank">獨立包團Whatsapp</a></li>
                </ul>
              </div>
              <!-- Single Widget -->
              <div class="footer_widget">
                <h3 class="widget_title">旅行團</h3>
                <ul class="widget-content">
                  <li><a href="tour?loc=1&type=top&title=全部歐洲">歐洲</a></li>
                  <li><a href="tour?loc=2&type=top&title=全部地中海/中東/中亞">地中海/中東/中亞</a></li>
                  <li><a href="tour?loc=3&type=top&title=全部非洲">非洲</a></li>
                  <li><a href="tour?loc=4&type=top&title=全部東南亞">東南亞</a></li>
                  <li><a href="tour?loc=5&type=top&title=全部中國長線">中國長線</a></li>
                  <li><a href="tour?loc=6&type=top&title=全部廣東省短線">廣東省短線</a></li>
                  <li><a href="tour?loc=7&type=top&title=全部澳紐/美加">澳紐/美加</a></li>
                  <li><a href="tour?loc=172&type=top&title=全部本地遊">本地遊</a></li>
                </ul>
              </div>
              <!-- Single Widget -->
              <!--
              <div class="footer_widget">
                <h3 class="widget_title">自由行套票</h3>
                <ul class="widget-content">
                  <li><a href="#">台灣</a></li>
                  <li><a href="#">日本</a></li>
                  <li><a href="#">韓國</a></li>
                  <li><a href="#">泰國</a></li>
                </ul>
              </div>
            -->
              <!-- Single Widget -->
              <div class="footer_widget">
                <h3 class="widget_title">關於星晨旅遊</h3>
                <ul class="widget-content">
                  <li><a href="news">最新動向</a></li>
                  <li><a href="aboutus">公司簡介</a></li>
                  <li><a href="contactus">分行資料/聯絡我們</a></li>
                  <li><a href="jobs">人才招聘</a></li>
                </ul>
              </div>
              <!-- Single Widget -->
              <div class="footer_widget">
                <h3 class="widget_title">旅遊資訊</h3>
                <ul class="widget-content">
                  <li><a href="info">旅客須知</a></li>
                  <li><a href="https://www.hongkongairport.com/tc/flights/arrivals/passenger.page" target="_blank">航班資料</a></li>
                  <li><a href="tax">旅遊保險</a></li>
                  <li><a href="tic">印花徵費</a></li>
                  <li><a href="https://www.xe.com/currencyconverter/" target="_blank">匯率</a></li>
                  <li><a href="tripshoot">領隊隨拍</a></li>
                  <li><a href="lecture">旅遊講座</a></li>
                </ul>
              </div>
              <!-- Single Widget -->
              <div class="footer_widget widget_socail">
                <h3 class="socail_widget_title">社交媒體</h3>
                <ul class="socail_menu" style="display:block">
                  <li class="social_mini_icon" style="width:50px">
                    <a href="https://www.facebook.com/MorningStarTravelHK">
                      <img src="/assets/img/icon-fb.png" alt="icon" />
                    </a>
                  </li>
                  <li class="social_mini_icon" style="width:50px">
                      <img src="/assets/img/icon-wechat.png" alt="icon"  style="width:50px"/>
                  </li>
                  <li style="width:200px">
                      <img src="/assets/img/wechat-01.png" alt="icon" >
                  </li>
                </ul>
              </div>
  
            </div>
            <div class="footer_bottom">
              <div class="footer_bottom_pragraph">
                <p class="footer_description">
                  *所有圖片只供參考。<br />
                  <font color=red>*網站內所有產品均受TIA監管。</font><br />
                </p>
              </div>
              <div class="footer_widget_menu">
                <ul>
                  <li><a href="assets/static/terms-and-conditions.pdf"> 免責條款</a></li>
                  <li><a href="assets/static/Security-and-Privacy.pdf"> 私隱政策</a></li>
                </ul>
              </div>
              <div class="footer_cp">
                <p>
                  Copyright© 2023 BAO SHINN INTERNATIONAL EXPRESS LIMITED. All Rights Reserved. 牌照號碼: 352614
                </p>
              </div>
              <div>
                電郵：<a href="mailto:cs@morningstartravel.com.hk">cs@morningstartravel.com.hk</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- Script Here -->
      <!--<a href="https://api.whatsapp.com/send/?phone=85292229610&text=你好，想詢問有關資料。&type=phone_number&app_absent=0" target="_blank">-->
      <div class="easychat" (click)="onScrollTop()">
      </div>
      <!--</a>-->
      <!-- easychat -->
      <!-- Script Here -->
      <script src="assets/js/script.js"></script>